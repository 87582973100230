window._stack.push([
  function (di, DOM, CSSTransitions) {
    var page = di.getService("page");
    var snippetManager = di.getService("snippetManager");

    var bindControlEvents = function (parentEl) {
      /* HERO SLIDER */
      var heroTextChangerItem = Array.from(
        parentEl.getElementsByClassName("hero__changer")
      );
      if (heroTextChangerItem.length > 1) {
        heroTextChanger(heroTextChangerItem);
      }
      /* HERO SLIDER */

      /* FLOATING ITEMS ON MAIN PAGE */
      var floatingItem = Array.from(
        parentEl.getElementsByClassName("hero__floating-item")
      );
      if (floatingItem.length) {
        var floatItem = DOM.getByClassName("hero__floating-item");
        floatItem.forEach(function (element) {
          var animationDuration = Math.floor(Math.random() * 30) + 20;
          var top = Math.floor(Math.random() * 80);

          element.style.animationDuration = animationDuration + "s";
          element.style.top = top + "vh";
        });
        Array.from(parentEl.getElementsByClassName("hero__floating")).forEach(function (element) {
          element.style.display = "block";
        });
      }
      /* FLOATING ITEMS ON MAIN PAGE */

      /* REFERENCES SLIDER */
      if (parentEl.getElementsByClassName("references-swiper").length) {
        referencesSlider();
      }
      /* REFERENCES SLIDER */

      /* CK EDITOR INIT */
      var initEditorItem = parentEl.querySelectorAll("[data-editor]");
      if (initEditorItem.length) {
        initEditor(initEditorItem, page);
      }
      /* CK EDITOR INIT */

      /* SIDE ITEMS SCROLL MOVING */
      if (parentEl.getElementsByClassName("side-item").length) {
        sideItemMove(parentEl);
      }
      /* SIDE ITEMS SCROLL MOVING */

      /* HERO BUTTON LINK REVEAL AFTER CLICK */
      var heroButtonReveal = Array.from(
        parentEl.getElementsByClassName("hero__button-reveal")
      );
      heroButtonReveal.forEach(function (element, index) {
        element.addEventListener("click", function (e) {
          e.preventDefault();

          e.target.classList.toggle("hero__button-reveal--active");
          e.target
            .closest(".hero__button")
            .lastElementChild.classList.toggle(
              "hero__button-dropdown--active"
            );
        });
      });
      /* HERO BUTTON LINK REVEAL AFTER CLICK */


      /* HAMBURGER MENU BUTTON CLICK EVENT */
      if (parentEl.getElementsByClassName("navbar-toggler").length) {
        document.getElementsByClassName("navbar-toggler")[0].addEventListener("click", function (e) {
          e.preventDefault();

          var toggler = e.target;
          if (!toggler.classList.contains('navbar-toggler')) {
            toggler = e.target.parentElement;
          }

          toggler.classList.toggle("navbar-toggler--close");
          document.getElementById("navbar-collapse").classList.toggle("show");
        });
      }
      /* HAMBURGER MENU BUTTON CLICK EVENT */

      /* FLASH MESSAGE CLOSE */
      var formAlertClose = parentEl.querySelectorAll(".alert .close");
      if (Array.from(formAlertClose).length) {
        parentEl
          .getElementsByClassName("close")[0]
          .addEventListener("click", function (e) {
            e.preventDefault();
            e.target.parentElement.classList.add("d-none");
          });
      }
      /* FLASH MESSAGE CLOSE */

      /* ADMIN CONTROLS CLICK REVEAL */
      var adminControlsElements = Array.from(
        parentEl.getElementsByClassName("admin-controls__select")
      );
      adminControlsElements.forEach(function (element, index) {
        element.addEventListener("click", function (e) {
          this.classList.toggle("admin-controls__select--active");
        });
      });
      /* ADMIN CONTROLS CLICK REVEAL */

      var mainAdminControlButtons = Array.from(parentEl.getElementsByClassName("admin-controls__main-button"));
      mainAdminControlButtons.forEach(function (element) {
        element.addEventListener("click", function (e) {
          var neco = this;
          Array.from(document.getElementsByClassName("admin-controls__block--reveal")).forEach(function (element) {
            if (element != neco.nextElementSibling) {
              element.classList.remove("admin-controls__block--reveal");
            }
          });

          this.nextElementSibling.classList.toggle("admin-controls__block--reveal");
        });
      });

      var appearElement = [];
      Array.from(parentEl.querySelectorAll(".appear")).forEach(function (element) {
        appearElement.push(element);
      });

      scrollAppearElement(appearElement);

      var videoElement = parentEl.querySelectorAll(".hero__img--video");
      if (videoElement.length && videoElement[0].paused) {
        setTimeout(function () {
          videoElement[0].play();
        }, 200);
      }

    };

    snippetManager.on("after-update", function (evt) {
      if ("update" in evt.data) {
        var updatedSnippets = evt.data.update;
        for (var s in updatedSnippets) {
          bindControlEvents(updatedSnippets[s].element);
        }
      }
    });

    window.addEventListener("scroll", function (e) {
      if (window.scrollY > 0) {
        DOM.getByClassName("navbar fixed-top")[0].classList.add("navbar--scroll");
      } else {
        DOM.getByClassName("navbar fixed-top")[0].classList.remove(
          "navbar--scroll"
        );
      }
    });

    bindControlEvents(document);
  },
  {
    DOM: "Utils.DOM",
    CSSTransitions: "Utils.CSSTransitions"
  }
]);

function heroTextChanger(heroTextChangerItem) {
  var heroAdminClass = document.getElementsByClassName("hero__admin").length;
  var simulateTouchEnabled = heroAdminClass ? false : true;
  var autoplayEnabled = false;

  if (simulateTouchEnabled) {
    autoplayEnabled = { delay: 7000 };
  }

  var mySwiper = new Swiper(".hero-swiper", {
    // Optional parameters
    loop: simulateTouchEnabled,
    effect: "fade",
    simulateTouch: simulateTouchEnabled,
    autoplay: autoplayEnabled,

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    pagination: {
      el: ".hero-pagination",
      type: "bullets",
      clickable: true
    }
  });

  if (document.querySelector(".admin-panel__clicker") !== null) {
    document
      .querySelector(".admin-panel__clicker")
      .addEventListener("click", function (e) {
        e.preventDefault();
        setTimeout(function () {
          mySwiper.update();
        }, 400);
      });
  }

  window.addEventListener('resize', function () {
    setTimeout(function () {
      mySwiper.update();
    }, 400);
  });
}

function referencesSlider() {
  new Swiper(".references-swiper", {
    loop: true,
    autoplay: {
      delay: 7000
    },
    breakpoints: {
      576: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 20
      }
    },
    // Optional parameters

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    pagination: {
      el: ".reference-pagination",
      type: "bullets",
      clickable: true
    }
  });
}

function initEditor(initEditorItem, page) {
  var timeout = null;
  var editor = [];

  CKEDITOR.disableAutoInline = true;

  initEditorItem.forEach(function (element, index) {
    var editorType = element.getAttribute("data-editor");

    if (editorType !== "false") {
      editor[index] = CKEDITOR.inline(element, {
        toolbar: [
          {
            name: "basicstyles",
            items: ["Bold", "Italic", "Underline", "Strike"]
          },
          {
            name: "clipboard",
            items: ["Cut", "Copy", "Paste", "-", "Undo", "Redo"]
          },
          {
            name: "paragraph",
            items: [
              "NumberedList",
              "BulletedList",
              "-",
              "JustifyLeft",
              "JustifyCenter",
              "JustifyRight",
              "JustifyBlock"
            ]
          },
          { name: "links", items: ["Link", "Unlink"] },
          { name: "document", items: ["Source"] }
        ]
      });
    }

    element.addEventListener("keyup", function () {
      clearTimeout(timeout);

      timeout = setTimeout(function () {
        var url = element.getAttribute("data-update-link");
        var data = element.innerHTML;

        if (
          editorType == "title" ||
          editorType == "link" ||
          editorType == "false"
        ) {
          var data = data
            .replace("<p>", "")
            .replace("</p>", "")
            .replace("<br>", "");
        }

        //console.log(data)
        if (data == "<p><br></p>") {
          data = "";
        }
        //console.log(data)
        var data = { data: data };

        page.open(url, "post", data, {
          element: element,
          background: true,
          transition: null
        });
      }, 1000);
    });

    element.addEventListener("blur", function () {
      var url = element.getAttribute("data-update-link");
      var data = element.innerHTML;

      if (
        editorType == "title" ||
        editorType == "link" ||
        editorType == "false"
      ) {
        var data = data
          .replace("<p>", "")
          .replace("</p>", "")
          .replace("<br>", "");
      }

      if (data == "<p><br></p>") {
        data = "";
      }

      var data = { data: data };

      if (!document.getElementsByClassName("cke_dialog_open").length) {
        page.open(url, "post", data, {
          element: element,
          background: true,
          transition: null
        });
      }
    });
  });
}

if (document.getElementsByClassName("admin-panel__clicker").length) {
  document
    .querySelector(".admin-panel__clicker")
    .addEventListener("click", function (e) {
      e.preventDefault();
      e.srcElement.parentElement.classList.toggle("admin-panel--active");
      document
        .getElementsByClassName("page-wrapper")[0]
        .classList.toggle("page-wrapper--moved");
    });
}

function sideItemMove(parentEl) {
  var elementFromTop = [];
  var randomMove = [];

  var sideItems = Array.from(
    parentEl.getElementsByClassName("side-item__item")
  );

  sideItems.forEach(function (element, index) {
    fromTop =
      document.body.scrollHeight /
      1.7 /
      document.getElementsByClassName("side-item__item")
        .length;
    element.style.top =
      window.outerHeight / 2 + fromTop * (index + 1) + "px";

    elementFromTop[index] = element.offsetTop;
    randomMove[index] = (
      Math.random() * (0.35 - 0.2) +
      0.2
    ).toFixed(2);
  });

  document.addEventListener("scroll", function () {
    sideItems.forEach(function (element, index) {
      element.style.top =
        elementFromTop[index] +
        window.scrollY * randomMove[index] +
        "px";
    });
  });
}

// function openMainMenu(mainNavigation, mainNavigationHeight) {
// 	mainNavigation.classList.toggle("show");

// 	if (mainNavigation.classList.contains("show")) {
// 		mainNavigation.style.height = mainNavigationHeight + "px";
// 	} else {
// 		mainNavigation.style.height = 0;
// 	}
// }

function scrollAppearElement(appearElement) {
  document.addEventListener("scroll", function () {
    var fromTop = window.scrollY;

    appearElement.forEach(function (element) {
      if (fromTop + (window.outerHeight / 2.5) > element.offsetTop) {
        element.classList.remove("appear");
      }
    });
  });
}

_context.invoke(function (HistoryAgent, Strings) {
  HistoryAgent.prototype._handleResponse = function (data, evt) {
    var payload = evt.data.response.getPayload();

    if ('snippets' in payload && 'snippet--title' in payload.snippets) {
      data.title = Strings.escapeHtml(payload.snippets['snippet--title']).replace(/&amp;amp;/g, '&');
    } else if (payload.title) {
      data.title = payload.title;
    }
  };
}, {
  HistoryAgent: 'Nittro.Page.HistoryAgent',
  Strings: 'Utils.Strings'
});
