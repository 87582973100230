_context.invoke(function(Nittro) {
    var builder = new Nittro.DI.ContainerBuilder({
        "params": {
            "page": {
                "scroll": {
                    "target": 0,
                    "scrollDown": true
                }
            },
            "dialogs": {
                "baseZ": 1050
            },
            "confirm": {
                "prompt": "Jste si jistý?",
                "confirm": "Ano",
                "cancel": "Ne"
            }
        },
        "extensions": {
            "ajax": "Nittro.Ajax.Bridges.AjaxDI.AjaxExtension()",
            "forms": "Nittro.Forms.Bridges.FormsDI.FormsExtension()",
            "page": "Nittro.Page.Bridges.PageDI.PageExtension()",
            "flashes": "Nittro.Flashes.Bridges.FlashesDI.FlashesExtension()",
            "routing": "Nittro.Routing.Bridges.RoutingDI.RoutingExtension()",
            "dialogs": "Nittro.Extras.Dialogs.Bridges.DialogsDI.DialogsExtension()",
            "confirm": "Nittro.Extras.Confirm.Bridges.ConfirmDI.ConfirmExtension()",
            "dropzone": "Nittro.Extras.DropZone.Bridges.DropZoneDI.DropZoneExtension()"
        },
        "services": {},
        "factories": {}
    });

    this.di = builder.createContainer();
    this.di.runServices();

});