window._stack.push([
    function (di, DOM) {
        var snippetManager = di.getService("snippetManager");

        var bindControlEvents = function (parentEl) {
            var controls = DOM.getByClassName("spam-control", parentEl);
            controls.forEach(function (control) {
                setTimeout(function () {
                    control.value = DOM.getData(control, "spam");
                }, 300);

                var container = DOM.closest(control, null, "form-group");
                DOM.addClass(container, "d-none");
            });
        };

        snippetManager.on("after-update", function (evt) {
            if ("update" in evt.data) {
                var updatedSnippets = evt.data.update;
                for (var s in updatedSnippets) {
                    bindControlEvents(updatedSnippets[s].element);
                }
            }

            if ("add" in evt.data) {
                var addedSnippets = evt.data.add;
                for (var s in addedSnippets) {
                    bindControlEvents(addedSnippets[s].element);
                }
            }
        });

        bindControlEvents(document);
    },
    {
        DOM: "Utils.DOM"
    }
]);
