window.addEventListener("load", function () {
  // obtain plugin
  var cc = initCookieConsent();

  // run plugin with your configuration
  cc.run({
    current_lang: "en",
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    auto_language: "document", // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function (user_preferences, cookie) {
      // callback triggered only once
    },

    onAccept: function (cookie) {
      // ...
    },

    onChange: function (cookie, changed_preferences) {
      // ...
    },

    languages: {
      cs: {
        consent_modal: {
          title: "Tato stránka využívá cookies!",
          description:
            "Na těchto webových stránkách používáme soubory cookies. Některé z nich jsou nezbytné, zatímco jiné nám pomáhají vylepšít tento web a váš uživatelský zážitek.",
          primary_btn: {
            text: "Povolit vše",
            role: "accept_all", // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: "Nastavení",
            role: "settings", // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: "Nastavení souborů cookies",
          save_settings_btn: "Uložit nastavení",
          accept_all_btn: "Povolit vše",
          reject_all_btn: "Zakázat vše",
          close_btn_label: "Zavřít",
          // cookie_table_headers: [
          //   { col1: 'Name' },
          //   { col2: 'Domain' },
          //   { col3: 'Expiration' },
          //   { col4: 'Description' }
          // ],
          blocks: [
            {
              title: "Využívání cookies 📢",
              description:
                "Cookies jsou malé textové soubory, které mohou být používány webovými stránkami pro zajištění funkčnosti webu a zlepšení uživatelského zážitku při procházení webu. Využivané cookies se dělí na několik kategorií. Nutné cookies, které jsou určeny pro základní provoz webu dle zákona můžeme na Vašem zařízení ukládat vždy. U ostatních kategorii potřebujeme Váš souhlas s jejich využíváním.",
            },
            {
              title: "Nutné soubory cookies",
              //description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
              toggle: {
                value: "necessary",
                enabled: true,
                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
              },
            },
            {
              title: "Analytické soubory cookies",
              //description: 'These cookies allow the website to remember the choices you have made in the past',
              toggle: {
                value: "analytics", // your cookie category
                enabled: false,
                readonly: false,
              }, //,
              // cookie_table: [             // list of all expected cookies
              //   {
              //     col1: '^_ga',       // match all cookies starting with "_ga"
              //     col2: 'google.com',
              //     col3: '2 years',
              //     col4: 'description ...',
              //     is_regex: true
              //   },
              //   {
              //     col1: '_gid',
              //     col2: 'google.com',
              //     col3: '1 day',
              //     col4: 'description ...',
              //   }
              // ]
            },
            {
              title: "Marketingové soubory cookies",
              //description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
              toggle: {
                value: "targeting",
                enabled: false,
                readonly: false,
              },
            },
            {
              title: "Více informací",
              description:
                'Pokud chcete vědět více informací o tom, jak cookies využíváme, neváhejte nás kontaktovat na <a class="cc-link" href="mailto:info@urbitech.cz">info@urbitech.cz</a>.',
            },
          ],
        },
      },
      sk: {
        consent_modal: {
          title: "Táto stránka využívá cookies!",
          description:
            "Na našich webových stránkach používame súbory cookies. Niektoré sú nevyhnutné, iné nám pomáhajú zlepšovať túto webovú stránku a váš používateľský zážitok.",
          primary_btn: {
            text: "Povoliť všetko",
            role: "accept_all", // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: "Nastavenie",
            role: "settings", // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: "Nastavenie súborov cookies",
          save_settings_btn: "Uložiť nastavenie",
          accept_all_btn: "Povoliť všetko",
          reject_all_btn: "Zakázať všetko",
          close_btn_label: "Zavrieť",
          blocks: [
            {
              title: "Využívanie cookies 📢",
              description:
                "Cookies sú malé textové súbory, ktoré môžu byť používané webovými stránkami na zaistenie funkčnosti webu a zlepšenie užívateľského zážitku pri prehliadaní webu. Využivané cookies sa delia na niekoľko kategórií. Nutné cookies, ktoré sú určené pre základnú prevádzku webu podľa zákona, môžeme na Vašom zariadení ukladať vždy. Pri ostatných kategóriách potrebujeme Váš súhlas s ich využívaním.",
            },
            {
              title: "Nutné súbory cookies",
              toggle: {
                value: "necessary",
                enabled: true,
                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
              },
            },
            {
              title: "Analytické súbory cookies",
              toggle: {
                value: "analytics", // your cookie category
                enabled: false,
                readonly: false,
              },
            },
            {
              title: "Marketingové súbory cookies",
              toggle: {
                value: "targeting",
                enabled: false,
                readonly: false,
              },
            },
            {
              title: "Viac informácií",
              description:
                'Pokiaľ chcete vedieť viac informácií o tom, ako cookies využívame, neváhajte nás kontaktovať na <a class="cc-link" href="mailto:info@urbitech.sk">info@urbitech.sk</a>.',
            },
          ],
        },
      },
    },
  });
});
